import { switchMap, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DNATranslateService } from './translate.service';
import { User } from '../../types';
import { UserService } from './user.service';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import packageInfo from '../../../../package.json';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    userService: UserService;
    translationService: DNATranslateService;
    router: Router;

    constructor(
        private inj: Injector,
        private authService: MsalService
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        this.userService = this.inj.get(UserService);
        this.translationService = this.inj.get(DNATranslateService);
        this.router = this.inj.get(Router);

        const user: User = this.userService.getUser();
        let headers: HttpHeaders;
        let dupReq: HttpRequest<any>;
        headers = req.headers
            .set('x-dna-origin', 'studio')
            .set('x-dna-clienttimestamp', new Date().getTime().toString())
            .set('x-dna-pzone', new Date().getTimezoneOffset().toString())
            .set('x-dna-language', this.translationService.getLanguage())
            .set('studio-version', packageInfo.version)
            .set('x-dna-workspace', user?.currentWorkspace?.id ?? '');

        if (this.userService.getClientIpAddress()) {
          headers = headers.set('True-Client-IP', this.userService.getClientIpAddress());
        }
        const account = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

        if (!account) {
            // Rediriger l'utilisateur vers la page de connexion s'il n'est pas connecté
            this.router.navigate(['connection']);
            return throwError(() => new Error('No active account found'));
        }

        if (req.url.indexOf(environment.server_url_studio()) > -1 && !headers.get('authorization') && user !== null) {
            headers = headers
                .set('authorization', `Bearer ${user.token}`)
                .set('x-dna-tokenv2', `Bearer ${account.idToken}`)
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache');

        } else {
            headers = headers
                .set('accept', 'application/vnd.dna.v1+json')
                .set('x-dna-subscription-name', 'digitexpert');
            if (!(['largedatarecords', 'powerbicapture'].some(elem => req.url.includes(elem) && req.method === 'GET'))) {
                headers = headers
                    .set('authorization', `Bearer ${account.idToken}`);
            }
        }

        dupReq = req.clone({ headers: headers });
        return next.handle(dupReq).pipe(
            tap((event: HttpEvent<any>) => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401 || err.status === 403) {
                            this.userService.removeUser();
                            this.router.navigate(['/']);
                        }
                        if (err.status === 200 && err.url.indexOf("workinprogress") > -1) {
                            this.userService.removeUser();
                            location.assign(err.url);
                        }
                    }
                }
            ),
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                    this.userService.removeUser();
                    this.router.navigate(['connection']);
                }
                return throwError(() => error);
            })
        );
    }
}
