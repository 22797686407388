<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="brickwall" style="height:auto">
  <div class="brickwall-filters">
    <div id="filters-click">
      <div id="create">
        <button class="btn btn-primary" (click)="openModalWorkspace()">
          <div>{{"CREATE" | translate}} </div>
          <div>{{"WORKSPACE" | translate}}</div>
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<br>
<div class="col-sm-12">
  <h2 class="parameter-text" translate>WORKSPACES</h2>
  <dna-workspaces-as-table [workspaces]="workspaces" (onRemove)="remove($event)" (onEditPreferencesModal)="openModalPreferences($event)" (onEditModalGeneral)="openModalWorkspace($event.idWorkspace, $event.usersToDisplay, $event.adminsToDisplay)"
     [users]="allUsers">
  </dna-workspaces-as-table>
</div>
