import {
  empty as observableEmpty,
  Observable,
  Subject
} from 'rxjs';

import { map, take, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';

import * as _ from 'lodash';
import {
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  DNATypes,
  GroupedFormula,
  TableHeader,
  User,
  Workflow,
  CampaignsMulti,
  ActionTypes,
  ModalContent,
  Hub,
  Result
} from '../../types';
import {
  ApplicationInsightsService
} from '../../shared/services/applicationInsights.service';
import {
  DNATranslateService
} from './../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../shared/services/errorManager.service';
import {
  FilterService
} from '../../shared/services/filter.service';
import {
  UserService
} from '../../shared/services/user.service';
import {
  UtilService
} from '../../shared/services/util.service';
import { MultiCampaignsService } from '../multi-campaigns.service';
import { CampaignService } from '../../campaigns/campaigns.service';
import { ReferenceTypeService } from '../../shared/services/reference-type.service';
import { SearchTextComponent } from '../../shared/dnaSearch/dnaSearchText.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'dna-multi-campaigns',
  templateUrl: './multi-campaigns.component.html',
  styleUrls: ['./multi-campaigns.component.less']
})
export class MultiCampaignsComponent implements OnInit, AfterViewInit {

  campaignsMulti: CampaignsMulti[];
  filter: any;
  language: string;
  listFormulas: GroupedFormula[] = [];
  listUsers: User[];
  listWorkflows: Workflow[] = [];
  showSpinner = false;
  isCollapsedCampaignsFilter = false;
  wkLabel: string;
  hubs: Hub[] = [];
  user: User;
  totalItems = 0;
  initTime = performance.now();

  tableHeaders$: Observable<TableHeader[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  @ViewChildren(SearchTextComponent) searchTextComponents: QueryList<SearchTextComponent>;

  constructor(
    private aiService: ApplicationInsightsService,
    private errorManagerService: ErrorManagerService,
    private filterService: FilterService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private campaignMultiService: MultiCampaignsService,
    private campaignService: CampaignService,
    private referenceType: ReferenceTypeService
  ) {}

  ngOnInit() {
    this.language = this.translateService.getLanguage();
    this.user = this.userService.getUser();
    this.hubs = this.referenceType.getHubs();
    this.filter = this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).campaignMulti;
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.CampaignsMulti);
    this.getCampaignMulti();
    this.getWorkflowTranslation();

    this.translateService.onLangChange().pipe(
      tap(event => this.language = event.lang),
      tap(() => this.getWorkflowTranslation()),
      tap(() => this.tableHeaders$ = this.utilService.createHeaders(DNATypes.CampaignsMulti)),
      tap(() => this.initObjectsLists(this.campaignsMulti, this.language)),
      takeUntil(this.destroy$))
      .subscribe();

    this.userService.onUserChanged().pipe(
      tap(() => this.getCampaignMulti()),
      takeUntil(this.destroy$))
      .subscribe();

    this.getCampaignMetadataAndSetUserName().pipe(
      tap((users: User[]) => this.listUsers = users))
      .subscribe(
        () => { },
        error => this.catchError(error)
      );
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('MultiCampaigns', '',  performance.now() - this.initTime, templateUrl);
  }

  catchError = (error) => {
    this.showSpinner = false;
    this.errorManagerService.catchError(error);
    return observableEmpty();
  }

  getCampaignMulti() {
    this.showSpinner = true;
    this.campaignMultiService.getHttpCampaignsWithFilter(this.filter).pipe(
      tap((result: Result) => this.campaignsMulti = result.list),
      tap((result: Result) => this.totalItems = result.totalItems),
      tap((result: Result) => [this.listFormulas, this.listWorkflows] = this.initObjectsLists(result.list, this.language)),
      finalize(() => this.showSpinner = false))
      .subscribe();
  }

  getCampaignMetadataAndSetUserName = (): Observable<User[]> => {
    return this.campaignService.getCampaignMetadata().pipe(
      take(1),
      map(meta => this.campaignService.displayUsersName(meta)));
  }

  getWorkflowTranslation() {
    this.translateService.translateMessage('WORKFLOW').pipe(
      mergeMap((wk: string) => this.translateService.translateMessage('ENTER_OBJECT_NAME_MASC', { object: wk.toLowerCase() })),
      tap((txt: string) => this.wkLabel = txt),
      take(1))
      .subscribe();
  }

  initFilters(event: any) {
    event.stopPropagation();
    this.filter = this.filterService.resetFilterCampaignMulti(this.userService.getUserHub(this.user.hub));
    this.clearFilterInputs();
    _.set(this.filter, 'hubs', new Array(this.userService.getUserHub(this.user.hub)));
  }

  initObjectsLists(campaignsMulti: CampaignsMulti[] = [], language: string = 'english'): [GroupedFormula[], Workflow[]] {
    const formulas = [], workflows = [];
    campaignsMulti.forEach((c) => {
      if (c.formulas) {
        c.formulas.forEach(fm => {
          formulas.push(fm.name);
        });
      }
      if (c.workflows) {
        c.workflows.forEach(wf => {
          const name = wf.name[language] ? wf.name[language] : wf.name.english;
          workflows.push({ name: name, id: wf.id });
        });
      }
    });
    return [_.uniq(formulas), _.uniqBy(workflows, 'name')];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onParametersChanged(object: any) {
    _.set(this.filter, 'page', _.get(object.pager, 'currentPage', 1));
    _.set(this.filter, 'limit', _.get(object.pager, 'pageSize', 10));
    this.getCampaignMulti();
  }

  searchCampaignMulti() {
   this.getCampaignMulti();
  }

  sortMulti = (idHeader: string, reverse: boolean = false) => {
    reverse ? _.set(this.filter, 'order', 'DESC') : _.set(this.filter, 'order', 'ASC');
    switch (idHeader) {
      case 'NAME':
        _.set(this.filter, 'orderBy', `name`);
        break;
      case 'DATE_MODIFICATION':
        _.set(this.filter, 'orderBy', `updated_on`);
        break;
    }
    this.getCampaignMulti();
  }

  updateData(text: any, type: string) {
    // conversion du text en type KeyValue[], tel que spécifié dans le type FilterSchedule
    // s'il n'y a pas de texte, on remet le filtre à son état initial
    if (type === 'bridge' || type === 'actiview') {
      this.filter[type] = text ? [{'key': text, 'value': text}] : [];
    } else {
      this.filter[type] = text ? text : '';
    }
  }

  updateDate(text: any, type: string) {
    this.filter[type] = text;
  }

  updateObject(value: any, object: any, property: any) {
    object[property] = value;
  }

  onClickActionButton(actionButton, campaignMulti) {
    switch (actionButton.id) {
      case ActionTypes.Delete:
        this.utilService.translateMessageModal('CONFIRM_DELETE', campaignMulti.name, 'THE_CAMPAIGN_MULTI').pipe(
          mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
          mergeMap(() => this.campaignMultiService.deleteCampaignMulti(campaignMulti.id)),
          tap(() => this.onSuccessDelete(campaignMulti))
          ).subscribe();
        break;
    }
  }

  onSuccessDelete(multi: CampaignsMulti) {
    this.campaignsMulti = this.campaignsMulti.filter(c => c.id !== multi.id);
    this.errorManagerService.displayMessage('ON_SUCCESS_DELETE');
  }

  clearFilterInputs() {
    this.searchTextComponents.forEach(searchText => searchText.clearInputs());
  }
}

