import * as type from './environment.type';

export const environment: type.ObjEnvironment = {
  production: true,
  version: '1.14.0',
  branchVersion: '@@branchversion',
  apiCoreUrl: 'https://dna-ww-pit.thecosmetrics.com/core',
  apiSharedUrl: 'https://dna-ww-pit.thecosmetrics.com/shared',
  apiLibrariesUrl: 'https://dna-ww-pit.thecosmetrics.com/libraries',
  apiApptalkUrl: 'https://dna-ww-pit.thecosmetrics.com/apptalk',
  apiStudiesUrl: 'https://dna-ww-pit.thecosmetrics.com/studies',
  apiAnswersUrl: 'https://dna-ww-pit.thecosmetrics.com/answers',
  publicIpEndpoint: 'https://jsonip.com/',
  msal: {
    clientId: 'defeff18-8b2d-47da-acad-2a3a98ab3c88',
    authority: 'https://login.microsoftonline.com/e4e1abd9-eac7-4a71-ab52-da5c998aa7ba',
    redirectUri: 'https://digitexpertstudio-pit.thecosmetrics.com/',
    postLogoutRedirectUri: 'https://digitexpertstudio-pit.thecosmetrics.com/'
  },
  server_url: function (): string {
    return 'https://digitexpertstudio-pit.thecosmetrics.com';
  },
  server_url_studio: function (): string {
    return this.server_url();
  },
  mobile_url: function (): string {
    return 'https://digitexpertmobile-pit.thecosmetrics.com/';
  },
  name: function (): string {
    return 'pit';
  },
  namehttp: function (): string {
    return 'pit';
  },
  bridge_url: function (): string {
    return 'https://bridge-ri.loreal.wans/Bridge/default.aspx?ACTION=MODIFY&REQ_NUMBER=';
  },
  milor_url: function (): string {
    return 'https://oa.preprod.nuxeocloud.com/nuxeo/ui/#!/doc/';
  },
  appInsights: {
    instrumentationKey: function (): string {
      if (window.location.hostname.indexOf("dna") === -1) {
        return '0096f00b-2326-443e-b688-f1fcc6827ff5';
      } else {
        return 'e501eb76-300c-4c58-9cff-269b77c0856a';
      }
    }
  },
  synergy_url: function (): string {
    return 'https://qua-synergy-ri.loreal.wans/Synergy/Demand/Edit/';
  },
  actiview_url: function (): string {
    return 'http://qua-ficheeval-ri.loreal.wans/FicheEval/SynthesisReport/Index/';
  },
  pfc_base_url: function () {
    return 'https://dev-pitperformancecenter-ri.loreal.wans/shell/'
  }
};
