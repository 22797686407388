import { HttpClient } from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {
  ARCSMetiers,
  AttributeData,
  DNAObject,
  Favorite,
  FicheCaracterisation,
  Hub,
  HubById,
  Hubs,
  HubsById,
  Metiers,
  Preference,
  TypeMetier,
  User
} from '@app/types';
import { LocalStorageService } from './localStorage.service';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';

export interface UserInfos {
  peopleHub: string;
  peopleKey: string;
  peopleName: string;
  peopleRoles: string;
  peopleUserKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userChanged: EventEmitter<User> = new EventEmitter<User>();
  ipAddress: string;
  metiers: typeof Metiers = Metiers;
  serverUrlStudio: string;

  //private _favorites = new BehaviorSubject<Favorite[]>(this.getFavoritesFromLocalStorage());
  //public favorites$ = this._favorites.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {
    this.serverUrlStudio = environment.server_url_studio();
  }

  private getFavoritesFromLocalStorage(): Favorite[] {
    return JSON.parse(this.localStorageService.getItem('favorites') || '[]');
  }


  filterAllUsersByWorkspace(formsCaracterisation: FicheCaracterisation[], metierName: TypeMetier): FicheCaracterisation[] {
    return formsCaracterisation.map(data => {
      data.AttributeData = this.filterByWorkspace(data.AttributeData, metierName, data.hub);
      return data;
    });
  }

  filterByWorkspace(data: AttributeData[], metierName: TypeMetier, hub: string = 'FR'): AttributeData[] {
    if (hub !== 'FR') {
      return data;
    }
    switch (metierName) {
      case this.metiers.Hair:
        const regHair = new RegExp(`^${ARCSMetiers.Hair}`);
        return data.filter(d => d.AttributeName.match(regHair));
      case this.metiers.Skin:
        const regSkin = new RegExp(`^${ARCSMetiers.Skin}`);
        return data.filter(d => d.AttributeName.match(regSkin));
      default:
        return data;
    }
  }

  getIdentities(): Observable<any> {
    return this.http.get<any>( `${environment.apiCoreUrl}/v1/accounts/identities`).pipe(
      map(resp => {
        return resp.list.map(u => {
          return {
            key: u.peopleKey,
            name: u.peopleName
          };
        });
      })
    );
  }

  getFavorites(peopleId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiSharedUrl}/v1/accounts/settings/${peopleId}`);
  }

  getClientIpAddress(): string {
    return this.ipAddress;
  }

  setClientIpAddress(ipAddress: string): void {
    this.ipAddress = ipAddress;
  }

  /**
   * Getter & Setter
   */
  getUser(): User {
    const user = this.localStorageService.getItem('DNA_User');
    this.initWorkspace(user);
    return user;
  }

  setUser(user: User, emit: boolean = true): void {
    this.initWorkspace(user);
    this.localStorageService.setItem('DNA_User', user);
    //Get browser ip address to use the correct region redirect
      this.http.get<{ ip: string }>(environment.publicIpEndpoint).subscribe(data => {
      this.setClientIpAddress(data.ip);
    });
    if (emit) {
      this.userChanged.emit(user);
    }
  }

  setPeopleUserKey(userKey: string): void {
    this.localStorageService.setItem('peopleUserKey', userKey);
  }

  getPeopleUserKey(): string {
    return this.localStorageService.getItem('peopleUserKey');
  }

  removeUserLocal() {
    this.localStorageService.remove('DNA_User');
  }

  getUserHub(id: HubById): Hub {
    switch (id) {
      case 'ME':
        return Hubs.Mexico;
      case 'ZA':
        return Hubs.SouthAfrica;
      case 'BR':
        return Hubs.Brazil;
      case 'CH':
      case 'CN':
        return Hubs.China;
      case 'FR':
        return Hubs.France;
      case 'IN':
        return Hubs.India;
      case 'JP':
        return Hubs.Japan;
      case 'US':
        return Hubs.US;
      default:
        return Hubs.France;
    }
  }

  getUserHubById(): HubById {
    const hub = this.getUser().hub;
    if (hub.valueOf() === 'CN') {
      return HubsById.China;
    }
    return hub;
  }

  initWorkspace(user: User) {
    if (user && user.currentWorkspace && !user.currentWorkspace.preferences) {
      user.currentWorkspace.preferences = new Preference();
    }
  }

  isAuthorized(role: string): boolean {
    const user: User = this.getUser();
    if (user && user.roles) {
      return !role ? true : (_.includes(user.roles, 'DNA_ADMIN') ? true : _.includes(user.roles, role));
    } else {
      return false;
    }
  }

  isFavorite(id: string): boolean {
    const user: User = this.getUser();
    return (user && user.favorites && id) ? user.favorites.find((f: Favorite) => f.id === id) !== undefined : false;
  }

  isTest(item: DNAObject): boolean {
    const user: User = this.getUser();
    return (user && user.tests && item) ? user.tests.find((t: Favorite) => t.id === item.id) !== undefined : false;
  }

  onUserChanged(): EventEmitter<User> {
    return this.userChanged;
  }

  removeUser() {
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  }

  private update(id: string, typeState: string, testsORfavorites: 'tests' | 'favorites') {
    const user: User = this.getUser();

    if (!user[testsORfavorites]) {
      user[testsORfavorites] = [];
    }

    const favIndex = user[testsORfavorites].findIndex((o: Favorite) => o.id === id);

    if (favIndex != -1) {
      user[testsORfavorites].splice(favIndex, 1);
    } else {
      user[testsORfavorites].push({
        id: id,
        type: typeState
      });
    }
    this.setUser(user, false);
  }

  updateFavorite(id: string, type: string): void {
    this.update(id, type, 'favorites');
    this.getFavoritesFromLocalStorage();
  }


  updateTest(item: DNAObject, typeState: string) {
    this.update(item.id, typeState, 'tests');
  }

  isConfort(): boolean {
    const user: User = this.getUser();
    return _.get(user, 'currentWorkspace.preferences.displayPanelConfort', false);
  }

  isAssignmentAfterPublishPossible(): boolean {
    const user: User = this.getUser();
    return _.get(user, 'currentWorkspace.preferences.parameters.displayAssignmentAfterPublish.isActive', false);
  }

  setFavorites(favorites: Favorite[]): void {
    const user: User = this.getUser();
    user.favorites = favorites;
    this.setUser(user);
  }


}
